:root {
  --primary-color: #2a2266;
}

$base-bg: #fff;
$base-text-color: rgb(0 0 0 / 87%);
$base-border-color: #e0e0e0;
$base-border-radius: 4px;
$base-accent: #9eb8e8;
$base-bg-second: rgba(#363640, 0.85);
$left-panel-bg-second:  rgba(#363640, .85);
