/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

/* Style the custom checkbox */
.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px; /* Adjust label font size */
}

.custom-checkbox .checkmark {
  width: 20px; /* Size of the checkbox */
  height: 20px;
  background-color: white; /* Default background color */
  border: 2px solid #ccc; /* Border color */
  border-radius: 4px; /* Rounded corners */
  display: inline-block;
  position: relative;
  margin-right: 8px; /* Space between checkbox and label */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Style the checkmark when checked */
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #2a2266; /* Checked background color */
  border-color: #2a2266;
}

/* Create the checkmark symbol */
.custom-checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 0;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 1.2px 1.2px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}
