@import 'material-icons/iconfont/material-icons.css'

/* PrimeNG Core */
@import "../node_modules/primeflex/primeflex.scss"
@import "../node_modules/primeicons/primeicons.css"

$gutter: 1rem // for primeflex grid system
@import "themes/pressure-theme/layout/styles/layout/layout.scss"

/* Pressure Theme */
@import "themes/pressure-theme/flags/flags.css"
@import "themes/pressure-theme/badges.scss"
@import "themes/pressure-theme/code.scss"

// *Pressure Custom components
@import "themes/pressure-theme/components/checkbox.scss"

/* Order */
@layer reset, primeng

/* Reset CSS */
@layer reset
  html
    font-family: 'Roboto', sans-serif
    height: 100vh
    background: #dbdbdb
  body
    margin: 0
    // line-height: 1 !important
    background-color: transparent!important

p-calendar
  display: inline-block
  width: 150px

.pressure-disable-user-actions
  pointer-events: none
  opacity: 0.5 /* Makes the element look disabled */
  cursor: not-allowed /* Changes the cursor to a 'not-allowed' symbol */
.p-inputnumber
  width: 100%
.p-chip-text
  white-space: nowrap
p-selectbutton
  .p-highlight
    cursor: none
    pointer-events: none
    -webkit-touch-callout: none
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

// TODO LEGACY BLOCK
.tooltip-text
  position: absolute
  background: black
  color: white
  padding: 5px
  border-radius: 10px
  top: -30px
  left: calc(50% - 50px)
  width: 100px

.tooltip
  position: relative

.example-full-width .mat-form-field-wrapper .mat-form-field-flex  .mat-form-field-infix
  padding: 0 0 0 0!important
.example-full-width .mat-form-field-wrapper .mat-form-field-flex
  padding: 0 5px 0 5px!important
.mat-form-field-appearance-fill .mat-form-field-flex
  background-color: rgba(0,0,0,0)
.amcharts-amexport-menu-level-0.amcharts-amexport-top
  margin-top: -20px!important
.content-center-maxwidth1920
  margin: 0 auto
  // max-width: 1920px
  box-sizing: border-box
.app-flex
  display: flex

.chartBarIndustry
  padding: 25px 30px
  background: white
  width: 750px
  transition: box-shadow .25s
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2)
  &:hover
    transition: box-shadow .25s
    box-shadow: 0 8px 17px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19)

.title-primary
  color: #666666
perfect-scrollbar.ps-show-active > .ps.ps--active-y > .ps-overlay:not(.ps-at-bottom) .ps-indicator-bottom
  opacity: 0 !important
perfect-scrollbar:hover.ps-show-active > .ps.ps--active-y > .ps-overlay:not(.ps-at-bottom) .ps-indicator-bottom
  opacity: 0.5 !important
perfect-scrollbar.ps-show-active > .ps.ps--active-y > .ps-overlay:not(.ps-at-top) .ps-indicator-top
  opacity: 0 !important
perfect-scrollbar:hover.ps-show-active > .ps.ps--active-y > .ps-overlay:not(.ps-at-top) .ps-indicator-top
  opacity: 0.5 !important
perfect-scrollbar
  overflow: visible !important
.title-popup-template
  padding: 15px 15px 10px 0
  display: flex
  flex-direction: row
  justify-content: space-between
.title-popup
  font-size: 18px
.title-table
  padding: 0 20px
  display: inline-grid
.ventBox
  padding-top: 30px
  label
    display: none
.danger-btn-iperl
  .dx-button.dx-button-danger
    background-color: #F44336 !important
.info-receipt-iperl
  width: 50%
  color: #F44336 !important
  position: absolute
  top: 145px
  max-width: 350px
  @media (max-width: 1020px)
    top: 115px
  @media (max-width: 380px)
    top: 135px
    font-size: 12px
.user-menu
  span
    @media screen and (max-width: 768px)
      display: block
// ***********адаптив таблиц**********************/
app-devices,app-accidents
  .dx-toolbar .dx-toolbar-items-container
    @media screen and (max-width: 940px)
      height: 110px
    @media screen and (max-width: 570px)
      height: 120px
  @media screen and (max-width: 570px)
    height: 58px
.dx-toolbar-after .dx-texteditor-container
  @media screen and (max-width: 940px)
    padding-top: 19px
app-volumes
  .dx-datagrid-header-panel
    @media screen and (max-width: 620px)
      height: 160px
  .dx-toolbar-before .dx-item.dx-toolbar-item.dx-toolbar-button
    @media screen and (max-width: 1020px)
      width: 850px
  .dx-datagrid-header-panel .dx-toolbar-before
    @media screen and (max-width: 1020px)
      display: flex
      flex-wrap: wrap
  .dx-toolbar .dx-toolbar-items-container
    @media screen and (max-width: 1020px)
      height: 120px
  .dx-toolbar-after
    @media screen and (max-width: 620px)
      top: 50px
      left: -15px
  .dx-toolbar .dx-toolbar-after
    @media screen and (max-width: 620px)
      padding-left: 0
app-devices
  .dx-toolbar-after
    @media screen and (max-width: 530px)
      top: 22px
app-vents
  .dx-datagrid-header-panel .dx-toolbar-before
    @media screen and (max-width: 940px)
      width: 80%
      display: flex
      flex-wrap: wrap
  .dx-toolbar-items-container
    @media screen and (max-width: 940px)
      display: flex
      flex-wrap: wrap
      max-width: 1300px
  .dx-toolbar-after
    @media screen and (max-width: 710px)
      left: 0
      top: 17px
  .dx-toolbar .dx-toolbar-items-container
    @media screen and (max-width: 710px)
      height: 120px
  .dx-toolbar .dx-toolbar-after
    padding-left: 0
  .dx-datagrid-header-panel .dx-toolbar-before
    @media screen and (max-width: 940px)
      top: 10px
  .dx-item.dx-toolbar-item.dx-toolbar-label
    @media screen and (max-width: 860px)
      display: none
app-devices,app-accidents,app-volumes,app-telem
  .dx-template-wrapper.dx-item-content.dx-toolbar-item-content
    @media screen and (max-width: 940px)
      width: 600px
    @media screen and (max-width: 530px)
      max-width: 400px
  .dx-toolbar-items-container
    @media screen and (max-width: 940px)
      display: flex
      flex-wrap: wrap
      max-width: 1300px
  .dx-datagrid-header-panel .dx-toolbar-before
    @media screen and (max-width: 940px)
      width: 80%
      display: flex
      flex-wrap: wrap
.dx-overlay-content
  .dx-toolbar-items-container
    @media screen and (max-width: 10240px)
      height: 120px
  .dx-datagrid-header-panel .dx-toolbar-after
    @media screen and (max-width: 1024px)
      top: 15px
      left: 0
      padding-left: 0
  .dx-datagrid-header-panel
    @media screen and (max-width: 1024px)
      padding: 0
  .dx-item.dx-toolbar-item.dx-toolbar-button
    @media screen and (max-width: 1024px)
      height: 10px
  .dx-datagrid-search-panel
    @media screen and (max-width: 1024px)
      margin-left: 0
  .dx-toolbar-before
    @media screen and (max-width: 1024px)
      height: 50px
      width: 100%
      padding-right: 0
  .template-wrapper
    @media screen and (max-width: 1024px)
      min-width: 105px
  .dx-toolbar .dx-texteditor
    @media screen and (max-width: 600px)
      width: 110px
  .volumes-date
    @media screen and (max-width: 600px)
      margin-right: 5px
.dx-item-content.dx-toolbar-item-content
  margin-left: 0
// **************новые стили для ng-zorro****************//
.ant-modal-content
  padding: 15px
.ant-layout-header
  background: none !important
  line-height: 0 !important
.anticon-zoom-in
  cursor: pointer
// ***********адаптив поп ап для планшета**********************/
.dx-overlay-modal
  // .dx-popup-content
  //   @media screen and (max-width: 1024px)
  //     overflow: scroll
  .dx-item-content.dx-box-item-content.dx-box-flex.dx-box.dx-widget.dx-visibility-change-handler.dx-collection
    @media screen and (max-width: 1024px)
      flex-direction: column !important
  .dx-scrollable-wrapper
    @media screen and (max-width: 1024px)
      max-width: 570px
      // height: 100% !important
  .dx-overlay-content
    @media screen and (max-width: 1024px)
      padding: 10px
      height: 90% !important
      width: 80%
      overflow-y: auto //// scroll
      max-width: 600px !important
      top: 50% !important
      left: 50% !important
      transform: translate(-50%, -50%) !important
  .dx-layout-manager .dx-field-item:not(.dx-first-col)
    @media screen and (max-width: 1020px)
      padding-left: 0 !important
  .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-title
    @media screen and (max-width: 1020px)
      height: 70px
  dx-tab-panel
    @media screen and (max-width: 1024px)
      min-height: 1100px !important
app-one-device
  .dx-tabs-wrapper
    margin-left: 20px
  .dx-datagrid-header-panel
    @media screen and (max-width: 1024px)
      height: 110px
  .dx-datagrid-search-panel
    @media screen and (max-width: 1024px)
      margin-left: 0
  .dx-toolbar .dx-toolbar-after
    @media screen and (max-width: 1024px)
      top: 30px
      left: 0
      padding-left: 0
// .dx-datagrid-content //accidents scroll
//   .dx-template-wrapper
//     overflow: auto
// .ps__rail-y,.ps__rail-x
  @media screen and (max-width: 1300px)
    opacity: 0 !important
    z-index: 100
    position: static !important
.ps__rail-y
  @media screen and (max-width: 1024px)
    height: auto !important
.ant-btn
  background-color: #2a2266 !important
  color: white !important
  font-size: 14px !important
  border-radius: 5px !important
  border: 1px solid transparent
.ant-btn:hover
    background: rgba(42, 34, 102, 0.75) !important

.ant-table-small .ant-table-thead > tr > th
    background-color: #f4f4f4  !important

.custom-footer-table > td
  background-color: #f4f4f4  !important

.ant-table-tbody > tr.ant-table-row:hover > td
    background: #e4e4e4  !important
.ant-table-filter-icon
  margin: 0 5px

// **************стили для таблиц общие****************//

.components-table-demo-control-bar
    margin-bottom: 12px
.nz-form-item
    margin-right: 16px
    margin-bottom: 8px
.block-error
    color: #f64b47
.search-box
    padding: 8px
.search-box input
    width: 188px
    margin-bottom: 8px
    display: flex
.search-box button
    width: 90px
.search-button
    margin-right: 8px
.selected-row

  color: black
.selected-all-row
  cursor: pointer
::ng-deep .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title
  padding: 0 4px 0 0
.button-print
  margin-right: 10px
  display: flex
  margin-bottom: 5px
  cursor: pointer
  background: #2a2266
  user-select: none
  text-decoration: none
  justify-content: center
  border-radius: 4px
  height: 30px
  width: 47px
  i
    font-size: 20px
    margin-top: 5px
    color: white

.container-top-grid
  display: flex
.filter-container
  width: 96%
nz-table
  margin-top: 8px
  margin-bottom: 12px
.alarm
  color: red !important
.accident
  color: #ffac11
.sensor_pressure_telemetry_accidents-block
  margin-bottom: 15px
  h3
    margin-top: 0
    padding-top: 0
dx-button
  margin-right: 15px
.button-set-threshold
  margin-left: 10px
  cursor: pointer
  color: white
  text-decoration: none
  background: #2a2266
  transition: 0.2s
  border: 1px solid transparent
  padding: 5px 12px
      // width: 115px
  text-align: center
  font-size: 14px
  border-radius: 5px
.button-set-threshold:hover
    background: rgba(42, 34, 102, 0.75)
.button-set-threshold-dropping-accidents
  background: #d9534f
.button-set-threshold-dropping-accidents:hover
  background: rgba(217, 83, 79, 0.84)
.button-disable
  background: rgba(217, 83, 79, 0.38) !important
  cursor: default
.accumulated-accidents-list, .current-accidents-list
  display: flex
  flex-direction: column
  span
    display: block
.icon-warn
  color: #d9534f
  font-size: 20px
.warn-strela
  color: #ffac11
  font-size: 16px
.flex-container-column-list
  display: flex
  justify-content: flex-start
  flex-wrap: wrap
  width: 700px
.column-list-visible-item
  background-color: #f4f4f4
  color: #383838
  cursor: pointer
  padding: 5px 10px 5px 10px
  margin-right: 5px
  margin-top: 10px
  span
    margin-left: 15px
.column-list-visible-item-visible
  background-color: #2a2266!important
  color: white!important
.column-list-visible-item-visible:hover
  background-color: rgba(42, 34, 102, 0.84) !important
  color: white!important
  i:hover
    color: #75d97a!important
.column-list-visible-item:hover
  background-color: #d1d1d1
  color: #383838
  i:hover
    color: #d9534f
.headFilterCustom
  .ant-table-filter-icon
    // position: absolute
    right: 5px
.flex-container-detail-two
  display: flex
  justify-content: flex-start
// **************конец стили для таблиц общие****************//
// *не понятно зачем оно .selected-row-a
  position: absolute
  width: 100%
  height: 100%*//
